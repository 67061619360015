import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Quick Links Section */}
        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <div className="link-columns">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/donate">Donate</Link></li>
              <li><Link to="/advertise-with-us">Advertise With Us</Link></li>
              <li><Link to="/book-ambulance">Book Ambulance</Link></li>
              <li><Link to="/register-ambulance">Register Ambulance</Link></li>
              <li><Link to="/vetenary-ambulance">Veterinary Ambulance</Link></li>
            </ul>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/events">Events</Link></li>
              <li><Link to="/media">Media</Link></li>
              <li><Link to="/achievements">Achievements</Link></li>
              <li><Link to="/trade-license">Trade License</Link></li>
            </ul>
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p><FaMapMarkerAlt className="footer-icon" /> House No.12, Janapath, Bye Lane-1, Navin Nagar, PO Zoo Road, Guwahati-24, Assam</p>
          <p><FaEnvelope className="footer-icon" /> <a href="mailto:clickthehummingbird@gmail.com">clickthehummingbird@gmail.com</a></p>
          <p><FaInstagram className="footer-icon" /> <a href="https://www.instagram.com/clickthehummingbird/" target="_blank" rel="noopener noreferrer">Instagram</a></p>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>&copy; 2024 The Hummingbird. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
