import React from 'react'
import HeroSection from '../../components/UI/HeroSection/HeroSection'
import AboutSection from '../../components/UI/aboutsection/AboutSection'
import RegisterAmbulanceBanner from '../../components/UI/registerambulancebanner/RegisterAmbulanceBanner'
import RegisterAmbulance from '../registerambulance/RegisterAmbulance'
import Footer from '../../components/footer/Footer'


const Home = () => {
  return (
    <>
    <HeroSection/>
    <AboutSection/>
    <RegisterAmbulanceBanner/>
    <RegisterAmbulance/>
    <Footer/>
    </>
  )
}

export default Home