import React, { useState } from 'react';
import './AdvertiseWithUs.css';

const AdvertiseWithUs = () => {
  const [formData, setFormData] = useState({
    mobileNumber: '',
    email: '',
    companyName: '',
    description: '',
    poster: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      poster: e.target.files[0]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <div className="advertise-page">
      <h1 className="heading">Advertise with us</h1>
      <p className="subheading">We accept advertisers with ISO Certification on our platforms</p>
      
      <form className="advertise-form" onSubmit={handleSubmit}>
      <p className="description">Fill-up the form for enquiry</p>
        <label htmlFor="mobileNumber">Mobile Number</label>
        <input
          type="tel"
          id="mobileNumber"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="companyName">Company Name</label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          required
        />

        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
        ></textarea>

        {/* <label htmlFor="poster">Upload Poster</label>
        <input
          type="file"
          id="poster"
          name="poster"
          onChange={handleFileChange}
          required
        /> */}

        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default AdvertiseWithUs;
