import React from 'react';
import './Donate.css';

const Donate = () => {
  return (
    <div className="donate-page">
      <h1 className="heading"> <img src="/logo/logo.png" alt="" />Payment Procedure</h1>
      <p className="subheading">(The payments are non-refundable)</p>
      <p className="donation-message">Any donations for HummingBird Initiative are appreciated</p>
      <div className="donate-content">
        <div className="donate-left">
          <h2>Scan here to donate</h2>
          <img src="/images/qr.png" alt="QR Code" className="qr-code" />
        </div>
        <div className="donate-right">
          <h2>Donate with UPI</h2>
          <div className="payment-options">
            <img src="/images/upi.png" alt="UPI Option 1" className="payment-option" />
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
