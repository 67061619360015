import React, { useState } from 'react';
import './Contact.css';
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_no: '',
    message: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    
    try {
      const response = await fetch('https://api.thehummingbird.org.in/api/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone_no: formData.phone_no,
          message: formData.message,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage('Message Sent Successfully!');
        console.log(data);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      setSuccessMessage('Failed to send message, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-page">
      <h1 className="contact-title">Contact Us</h1>

      <div className="contact-content">
        <div className="contact-form">
          <form onSubmit={handleSubmit} className="form-section">
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone_no">Your Phone Number</label>
              <input
                type="text"
                id="phone_no"
                name="phone_no"
                value={formData.phone_no}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
        </div>

        <div className="contact-details">
          <div className="contact-info">
            <p className="info-item"><strong>Email:</strong> <a href="mailto:clickthehummingbird@gmail.com">clickthehummingbird@gmail.com</a></p>
            <p className="info-item"><strong>Address:</strong> House No.12, Janapath, Bye Lane-1, Navin Nagar, PO Zoo Road, Guwahati-24, Assam</p>
          </div>

          <div className="social-media">
            <p className="social-media-title">Follow us on Social Media:</p>
            <div className="social-icons">
              <a href="https://www.instagram.com/clickthehummingbird/" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaInstagram />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaFacebook />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FaTwitter />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
