import React from 'react';
import './HeroSection.css';
import { Link } from 'react-router-dom';
import TradeLicense from '../../../pages/tradelicense/TradeLicense';

const HeroSection = () => {
  return (
    <div className="hero-section">
      <video className="hero-video" src="/background/bg.mp4" autoPlay loop muted />
      <div className="hero-content">
        <div className="hero-left">
            <div className="hero-heading"> 
                {/* <img src="/logo/logo.png" alt="Brand Logo" className="brand-logo" /> */}
                The HummingBird
            </div>
         
          <p className="description">
            Swift Care, Always There.
          </p>
<div className='trade-license'>
  <Link to='/trade-license'> Trade License by Guwahati Municipal Corporation, Guwahati </Link>
</div>
          <div className="buttons">
            <Link className='button' to='/register-ambulance'> Register an Ambulance</Link>
            <Link className='button' to='/book-ambulance'> Book an Ambulance </Link>
          </div>
        </div>
        <div className="hero-right">
          <img src="/logo/logo.png" alt="Hero" className="hero-image" />
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
