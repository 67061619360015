import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BookedAmbulance.css';

const BookedAmbulance = () => {
    const [booking, setBooking] = useState(null);
    const [ambulance, setAmbulance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(true);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const fetchBookingData = async () => {
            const bookingId = localStorage.getItem('booking_id');
            if (bookingId) {
                try {
                    const response = await fetch(`https://api.thehummingbird.org.in/api/ambulance-bookings/${bookingId}`);
                    if (response.ok) {
                        const result = await response.json();
                        if (result) {
                            setBooking(result);
                            if (result.approved_by) {
                                // Fetch ambulance details if booking is approved
                                fetchAmbulanceDetails(result.approved_by);
                                setWaiting(false); // Ambulance is approved
                            }
                        } else {
                            handleBookingNotFound();
                        }
                    } else {
                        // Handle non-ok responses (e.g., 404 Not Found)
                        if (response.status === 404) {
                            handleBookingNotFound();
                        } else {
                            const result = await response.json();
                            setError(result.message || 'Error fetching booking data');
                        }
                    }
                } catch (err) {
                    setError('An error occurred while fetching booking data.');
                } finally {
                    setLoading(false);
                }
            } else {
                setError('No booking ID found in local storage.');
                setLoading(false);
            }
        };

        const fetchAmbulanceDetails = async (ambulanceId) => {
            try {
                const response = await fetch(`https://api.thehummingbird.org.in/api/ambulances/${ambulanceId}`);
                const result = await response.json();
                if (response.ok) {
                    setAmbulance(result);
                } else {
                    setError(result.message || 'Error fetching ambulance details');
                }
            } catch (err) {
                setError('An error occurred while fetching ambulance details.');
            }
        };

        const handleBookingNotFound = () => {
            // Remove booking_id from local storage
            localStorage.removeItem('booking_id');
            // Show an error message and refresh the page
            setError('Booking not found. The booking ID has been removed from local storage.');
            setLoading(false);
            setTimeout(() => {
                window.location.reload(); // Refresh the page
            }, 2000); // Delay to show error message before refreshing
        };

        fetchBookingData();
        const intervalId = setInterval(fetchBookingData, 15000); // Poll every 15 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const handleCancelBooking = async () => {
        if (!cancelReason) {
            alert('Please select a cancellation reason.');
            return;
        }

        setSubmitting(true);

        try {
            const response = await fetch('https://api.thehummingbird.org.in/api/cancellations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    booking_id: booking.id,
                    reason: cancelReason,
                    cancelled_by: 'User',
                }),
            });

            const result = await response.json();

            if (response.ok) {
                // Remove the booking ID from local storage
                localStorage.removeItem('booking_id');
                
                alert('Booking cancelled successfully.');
                setShowCancelPopup(false); // Close the popup
                navigate('/'); // Navigate to homepage after cancellation
            } else {
                alert(result.message || 'Failed to cancel booking.');
            }
        } catch (error) {
            alert('An error occurred while cancelling the booking.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="booked-ambulance-container">
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {waiting && !loading && !error && (
                <div className="loading-container">
                    <div className="loader"></div>
                    <p className="loading-text">Waiting for an ambulance to accept...(Our App is in a Testing Phase)</p>
                    <button className="cancel-button" onClick={() => setShowCancelPopup(true)}>
                        Cancel Booking
                    </button>
                </div>
            )}
            {!waiting && booking && (
                <div className='booked-ambulance'>
                    <h1>Your Ambulance Booking is Approved</h1>
                    {ambulance && (
                        <div className="ambulance-details">
                            <h2>Approved Ambulance Details</h2>
                            <p><strong>Owner Name:</strong> {ambulance.owner_name}</p>
                            <p><strong>Phone Number:</strong> {ambulance.phone_number}</p>
                            <p><strong>Email Address:</strong> {ambulance.email_address}</p>
                            <p><strong>Ambulance Type:</strong> {ambulance.ambulance_type}</p>
                            <p><strong>License Number:</strong> {ambulance.license_number}</p>
                            <p><strong>City:</strong> {ambulance.city}</p>
                            <p><strong>Created At:</strong> {new Date(ambulance.created_at).toLocaleString()}</p>
                            <a href={`tel:${ambulance.phone_number}`} className="call-owner-button">
                                Call Owner
                            </a>
                        </div>
                    )}
                    
                    <p>Your Location: {booking.coordinates}</p>
                  
                    <p>Booked at: {new Date(booking.created_at).toLocaleString()}</p>
                    <button className="cancel-button" onClick={() => setShowCancelPopup(true)}>
                        Cancel Booking
                    </button>
                </div>
            )}
            {showCancelPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>Cancel Booking</h2>
                        <p>Select a reason for cancellation:</p>
                        <select value={cancelReason} onChange={(e) => setCancelReason(e.target.value)}>
                            <option value="">Select a reason</option>
                            <option value="No longer needed">No longer needed</option>
                            <option value="Delayed response">Delayed response</option>
                            <option value="Incorrect information">Incorrect information</option>
                            <option value="Other">Other</option>
                        </select>
                        <div className="popup-actions">
                            <button onClick={() => setShowCancelPopup(false)}>Close</button>
                            <button onClick={handleCancelBooking} disabled={submitting}>
                                {submitting ? 'Cancelling...' : 'Confirm Cancellation'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookedAmbulance;
