import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import './BookAmbulance.css';

const BookAmbulance = () => {
  const [ambulanceCategory, setAmbulanceCategory] = useState(null); // Human or Animal
  const [ambulanceType, setAmbulanceType] = useState(null); // General or Special
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState('');
  const [geoError, setGeoError] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage button loader
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a booking ID in local storage and navigate if present
    const bookingId = localStorage.getItem('booking_id');
    if (bookingId) {
      navigate('/booked-ambulance');
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`${latitude},${longitude}`);
        },
        (error) => {
          setGeoError('Unable to retrieve location. Please enter manually.');
          console.error('Geolocation error:', error);
        }
      );
    } else {
      setGeoError('Geolocation is not supported by this browser.');
    }
  }, [navigate]);

  const handleCategorySelection = (category) => {
    setAmbulanceCategory(category);
    setAmbulanceType(null); // Reset ambulance type when category changes
  };

  const handleTypeSelection = (type) => {
    setAmbulanceType(type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading state

    // Validate form data
    if (!phoneNumber || !location || !ambulanceCategory || !ambulanceType) {
      alert('Please fill in all fields.');
      setLoading(false); // Stop loading state
      return;
    }

    try {
      const response = await fetch('https://api.thehummingbird.org.in/api/ambulance-bookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          phone_number: phoneNumber,
          coordinates: location,
          ambulance_type: ambulanceType,
          approval_status: 'pending',
        }),
      });

      const result = await response.json();
      if (response.ok) {
        // Save the booking ID to local storage
        localStorage.setItem('booking_id', result.id);
        alert('Ambulance booked successfully!');

        // Navigate to a different page
        navigate('/booked-ambulance'); // Replace '/booked-ambulance' with your actual route
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error('Error booking ambulance:', error);
      alert('An error occurred while booking the ambulance.');
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <div className="book-ambulance-page">
      <p className='subheading'>Refunds are applicable for registered ambulances.</p>
      {!ambulanceCategory ? (
        <div className="type-selection">
          <h1 className="heading">Which type of ambulance would you like to book?</h1>
          <button onClick={() => handleCategorySelection('human')} className="option-btn">
            Human Ambulance
          </button>
          <button onClick={() => handleCategorySelection('animal')} className="option-btn">
            Animal Ambulance
          </button>
        </div>
      ) : !ambulanceType ? (
        <div className="type-selection">
          <h1 className="heading">
            {ambulanceCategory === 'human'
              ? 'Which type of Human Ambulance?'
              : 'Book an Animal Ambulance'}
          </h1>
          {ambulanceCategory === 'human' ? (
            <>
              <button onClick={() => handleTypeSelection('general')} className="option-btn">
                General Ambulance
              </button>
              <button onClick={() => handleTypeSelection('special')} className="option-btn">
                Special Ambulance (Quick Response)
              </button>
            </>
          ) : (
            <button onClick={() => handleTypeSelection('general')} className="option-btn">
              General Ambulance
            </button>
          )}
        </div>
      ) : (
        <div>
          <h1 className="heading">
            {ambulanceCategory === 'human'
              ? ambulanceType === 'general'
                ? 'Book a General Human Ambulance'
                : 'Book a Special Human Ambulance'
              : 'Book a General Animal Ambulance'}
          </h1>
          <p className="subheading">
            {ambulanceType === 'general'
              ? 'We will send your request to all nearby general ambulance services'
              : 'We will send your request to all nearby special ambulance services'}
          </p>
          <form className="ambulance-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <FaPhoneAlt className="icon" />
              <input
                type="tel"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <FaMapMarkerAlt className="icon" />
              <input
                type="text"
                placeholder={geoError || "Fetching your live location..."}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                readOnly={!geoError} // Allow editing only if there was an error fetching location
                required
              />
            </div>
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? 'Booking...' : 'Book Ambulance'}
            </button>
          </form>
        </div>
      )}

      <p className='subheading padding-top'>Our Service is only available in Guwahati at present.</p>
    </div>
  );
};

export default BookAmbulance;
