import React from 'react';
import './RegisterAmbulanceBanner.css';
import QRCode from 'qrcode.react';

const RegisterAmbulanceBanner = () => {
  return (
    <div className="register-ambulance-banner">
      {/* Background Video */}
      <video className="background-video" src="/background/bg.mp4" autoPlay loop muted />
      
      <div className="left-side">
        <h1>Register an Ambulance</h1>
        <h3>Scan the QR to Register your AMBULANCE</h3>
        <div className="qr-code-wrapper">
          <QRCode
            value="https://thehummingbird.org.in/register-ambulance"
            size={200}
            fgColor="#3B2666"
            bgColor="white"
            level="H"
            style={{ borderRadius: '20px' }}
          />
        </div>
      </div>

      <div className="right-side">
        <div className="rule-box">
          <h4>Valid Licensing</h4>
          <p>
            All registered ambulances must possess and maintain valid operating licenses issued
            by the relevant health authorities. Proof of licensing must be provided during the
            registration process.
          </p>
        </div>

        <div className="rule-box">
          <h4>Compliance with Standards</h4>
          <p>
            Registered ambulances must adhere to all local and national health and safety
            standards. This includes maintaining up-to-date medical equipment and ensuring all
            staff are certified healthcare professionals.
          </p>
        </div>

        <div className="rule-box">
          <h4>Timely Response</h4>
          <p>
            Registered ambulances are expected to respond promptly to patient calls. Failure to
            maintain an acceptable response time may result in suspension or termination of
            registration.
          </p>
        </div>

        <div className="rule-box">
          <h4>Accurate Information</h4>
          <p>
            All information provided during registration, including contact details and
            operational areas, must be accurate and kept up-to-date. Any changes must be reported
            immediately to ensure efficient service delivery.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterAmbulanceBanner;
