import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="about-left">
        <img src="/logo/logo.png" alt="Brand Logo" className="brand-logo" />
        <h1 className="brand-name">The HummingBird Initiative</h1>
      </div>
      <div className="about-right">
        <h2>About</h2>
        <h3>The HummingBird Initiative</h3>
        <p>
          The HummingBird Initiative is dedicated to providing rapid and reliable emergency medical response services. Our platform connects patients in need with registered hospitals and ambulances through a simple, efficient system. By utilizing QR codes for easy registration and an immediate notification system, we ensure that help is just a call away.
        </p>
        <h3>Our Mission</h3>
        <p>
          Our mission is to streamline emergency medical services, reducing response times and improving outcomes for patients in critical conditions. We aim to bridge the gap between those in need and those equipped to help, ensuring that no call for help goes unanswered.
        </p>
      </div>
    </div>
  );
}

export default AboutSection;
