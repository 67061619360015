import React, { useState } from 'react';
import './RegisterAmbulance.css';
import { Link } from 'react-router-dom';

const RegisterAmbulance = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    ambulanceType: '',
    licenseNumber: '',
    city: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <div className="register-ambulance-page">

<div className="invitation-letter">
        <h2>Invitation to Integrate Your Ambulance Services with "The Hummingbird"</h2>
        <div className="trade-license">
        <Link to='/trade-license'> Trade License by Guwahati Municipal Corporation, Guwahati </Link>
        </div>
        <p>Dear Sir/Ma'am,</p>
        <p>
          I hope this message finds you well. I am pleased to introduce "The Hummingbird," a pioneering website designed
          to integrate all ambulance services in our city. Our platform aims to assist patients who often struggle to
          access ambulance services due to the sheer number of available options and lack of information.
        </p>
        <p><strong>Our Goal:</strong> To create a centralized system where patients can easily request an ambulance with just a phone number, ensuring they receive a call back from registered ambulances promptly. Our service includes a map locator feature to help ambulances efficiently locate patients in need.</p>
        
        <h3>Benefits of Integration:</h3>
        <ul>
          <li>Streamlined access for patients to your ambulance services;</li>
          <li>Enhanced visibility for your ambulances within the community;</li>
          <li>Improved response times with our map locator feature;</li>
          <li>Opportunity to be part of a city-wide network dedicated to patient care;</li>
          <li>Tip facility for ambulance drivers</li>
        </ul>
        
        <h3>Integration Details:</h3>
        <ul>
          <li>A one-time integration fee of Rs. 500 per ambulance</li>
          <li>Simple registration process ensuring quick setup</li>
        </ul>
        
        <p>
          We believe that partnering with "The Hummingbird" will enable both our organizations to provide superior service to those in need. Your hospital’s involvement will be instrumental in achieving our shared goal of improving emergency medical services in our city.
        </p>
        
        <p>Thank you for considering this opportunity. We look forward to the possibility of working together to enhance the care we provide to our community.</p>
        
        <p>
          Warm Regards,<br />
          Priyanka Chakrabarty<br />
          Founder
        </p>
      </div>


      <h1 className="page-title">Register Your Ambulance</h1>

     <img className='register-qr' src='/images/register.png' />
    
    </div>
  );
};

export default RegisterAmbulance;
