import React from 'react';
import './AboutTheFounder.css';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const AboutTheFounder = () => {
  return (
    <div className="about-founder-section">
      <div className="top-section">
        {/* Left Side: Contact Info */}
        <div className="contact-details">
          <p className="contact-item pink-text">Phone: +91-9101417026</p>
          <p className="contact-item pink-text">Email: clickthehummingbird@gmail.com</p>
        </div>

        {/* Center: Avatar and Name */}
        <div className="founder-info">
          <div className="founder-avatar">
            <img src="/images/founder.png" alt="Priyanka Chakrabarty" />
          </div>
          <h2 className="founder-name">Priyanka Chakrabarty</h2>
          <p className="founder-position">CEO, Founder</p>
        </div>

        {/* Right Side: Social Media */}
        <div className="social-media">
          <div className="social-media-box">
            <h4 className="white-text">Social Media</h4>
            <div className="social-icons">
              
             <a href='https://www.instagram.com/clickthehummingbird/'> <FaInstagram className="social-icon" /></a> 
           
              <FaFacebookF className="social-icon" />
              <FaLinkedinIn className="social-icon" />
              <FaTwitter className="social-icon" />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom: Description */}
      <div className="founder-description">
        <p>
          Founded by Priyanka Chakrabarty, a postgraduate from Gauhati University and currently pursuing Executive MBA from IIT Patna.
        </p>
        <p>
          The Hummingbird aims to streamline emergency medical services, reducing response times and improving outcomes for patients in critical conditions. We aim to bridge the gap between those in need and those equipped to help, ensuring that no call for help goes unanswered.
        </p>
      </div>
    </div>
  );
};

export default AboutTheFounder;
